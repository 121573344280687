<script>
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import { getValue, mapArray } from '@emobg/web-utils';

import DamageSide from '../../../VehicleDamages/components/DamageSide';

import { DAMAGE_BLUEPRINT_SIDE_NAMES } from '../../../VehicleDamages/damages.const';

export default {
  name: 'DamagesComponent',
  components: {
    DamageSide,
  },
  props: {
    damages: {
      type: Array,
      default: () => [],
    },
    sides: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    transformDamages() {
      const grouped = groupBy(this.damages, 'side');
      const mappedDamages = map(
        this.sides,
        sides => ({
          ...sides,
          label: getValue(DAMAGE_BLUEPRINT_SIDE_NAMES, `${sides.side}.label`, sides.side),
          damages: grouped[sides.side] || [],
        }),
      );

      return sortBy(mappedDamages, damage => getValue(DAMAGE_BLUEPRINT_SIDE_NAMES, `${damage.side}.position`, 0));
    },
  },
  methods: {
    mapArray,
  },
};
</script>

<template>
  <div class="DamagesComponent row">
    <div
      v-for="side in transformDamages"
      :key="side.name"
      class="col-md-3"
    >
      <DamageSide
        :src="side.url"
        :label="side.label"
        :alt="side.name"
        :dots="mapArray(side.damages, damage => ({
          x: damage.coordinate_x,
          y: damage.coordinate_y,
        }))"
      />
    </div>
  </div>
</template>
