var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "DetailsDamagesView" },
    [
      _c("DamagesComponent", {
        staticClass: "py-3",
        attrs: { damages: _vm.damages, sides: _vm.sides },
      }),
      _c(
        "div",
        {
          staticClass: "d-flex my-2 justify-content-between align-items-center",
        },
        [
          _c("h1", [_vm._v(" Vehicle damages ")]),
          _c(
            "div",
            [
              _c(
                "ui-button",
                {
                  staticStyle: { "min-width": "fit-content" },
                  attrs: { "data-test-id": "create_damage-button" },
                  on: {
                    clickbutton: function ($event) {
                      _vm.$router.push({
                        name: _vm.fleet.damages.create,
                        params: {
                          vehicleUuid: _vm.getValue(
                            _vm.generalData,
                            "uuid",
                            null
                          ),
                        },
                      })
                    },
                  },
                },
                [_vm._v(" Report new damage ")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "position-relative" },
        [
          _vm.isLoading
            ? _c("ui-loader", { attrs: { absolute: "" } })
            : _vm._e(),
          _c("MuiAlgoliaList", {
            ref: "damages",
            staticClass: "DetailsDamagesView__list",
            attrs: {
              "item-actions": _vm.damagesActions,
              filters: _vm.damagesFilter,
              facets: _vm.damageFacets,
              index: _vm.DAMAGES_INDEX,
              "table-config": _vm.damagesContentCells,
              "is-search-enabled": false,
              "is-export-enabled": "",
            },
          }),
        ],
        1
      ),
      _vm.isPreviewModalOpen
        ? _c("PreviewDamageImageModal", {
            attrs: { image: _vm.imageToPreview },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
      _vm.isDeleteModalVisible
        ? _c("DeleteDamageModal", {
            attrs: {
              damage: _vm.damageToDelete,
              "on-delete-damage": _vm.refreshAlgoliaList,
            },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }