<script>
import get from 'lodash/get';
import { mapGetters } from 'vuex';
import { MuiAlgoliaList } from '@emobg/motion-ui';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { contentCells } from '../../../Bookings/config';
import { bookingListFacets } from './const/bookingsFacets';

export default {
  name: 'DetailsBookingsView',
  components: { MuiAlgoliaList },
  data() {
    return {
      vehicleUuid: get(this, '$route.params.vehicleUuid'),
    };
  },
  computed: {
    ...mapGetters(DOMAINS_MODEL.app.userAccount, [
      'areNonConnectedVehiclesAllowed',
      'operatorTimezone',
    ]),
    contentCells() {
      return contentCells(this.operatorTimezone, this.areNonConnectedVehiclesAllowed);
    },
    bookingListFilters() {
      return `vehicle_uuid:${this.vehicleUuid}`;
    },
  },
  created() {
    this.bookingListFacets = bookingListFacets.bind(this)();
    this.BOOKINGS_INDEX = ALGOLIA_INDEXES.csBookingsStartDesc;
  },
};
</script>

<template>
  <div class="DetailsBookingsView">
    <h1 class="mb-2">
      Vehicle bookings
    </h1>

    <MuiAlgoliaList
      :export-columns="contentCells"
      :facets="bookingListFacets"
      :filters="bookingListFilters"
      :index="BOOKINGS_INDEX"
      :table-config="contentCells"
      is-export-enabled
      is-sidebar-collapsed
      is-sidebar-hidden
    />
  </div>
</template>
