<script>
import get from 'lodash/get';
import { mapActions, mapGetters, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { FilterTableComponent } from '@/components';
import { CONNECTIVITY_SCHEMA } from './config/tableConfig';
import { VEHICLE_LOGS_SCOPES } from '../store/LogsModule';

export default {
  name: 'ConnectivityView',
  components: { FilterTableComponent },
  data() {
    return {
      vehicleUuid: get(this, '$route.params.vehicleUuid'),
    };
  },
  computed: {
    ...mapGetters(DOMAINS_MODEL.app.userAccount, [
      'operatorTimezone',
    ]),
    ...mapState(DOMAINS_MODEL.carsharing.vehicle.details.information, {
      licensePlate: state => state.general.data.license_plate,
    }),
    ...mapState(DOMAINS_MODEL.carsharing.vehicle.details.logs, {
      connectivityLogData: state => state[VEHICLE_LOGS_SCOPES.connectivityLogs].data,
      connectivityLogLoading: state => state[VEHICLE_LOGS_SCOPES.connectivityLogs].STATUS.LOADING,
    }),
    connectivitySchema() {
      return CONNECTIVITY_SCHEMA({
        timezone: this.operatorTimezone,
      });
    },
    connectivityExportSchema() {
      return [
        ...this.connectivitySchema,
        {
          header: 'License plate',
          template: () => this.licensePlate,
        },
      ];
    },
  },
  async created() {
    await this.getVehicleConnectivitiesLog({ vehicleUuid: this.vehicleUuid });
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.carsharing.vehicle.details.logs, [
      'getVehicleConnectivitiesLog',
    ]),
  },
};
</script>

<template>
  <div class="LogsEventsView">
    <h1 class="mb-2">
      Connectivity
    </h1>
    <FilterTableComponent
      :schema="connectivitySchema"
      :export-schema="connectivityExportSchema"
      :data="connectivityLogData"
      :is-loading="connectivityLogLoading"
      exportable
    />
  </div>
</template>
