<script>
import get from 'lodash/get';
import { mapActions, mapGetters, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { FilterTableComponent } from '@/components';
import { VEHICLE_LOGS_SCOPES } from '../store/LogsModule';
import { CHARGE_STATUS_SCHEMA } from './config/tableConfig';

export default {
  name: 'ChargingStatusView',
  components: {
    FilterTableComponent,
  },
  data() {
    return {
      vehicleUuid: get(this, '$route.params.vehicleUuid'),
    };
  },
  computed: {
    ...mapGetters(DOMAINS_MODEL.app.userAccount, [
      'operatorTimezone',
    ]),
    ...mapState(DOMAINS_MODEL.carsharing.vehicle.details.information, {
      licensePlate: state => get(state, 'general.data.license_plate'),
    }),
    ...mapState(DOMAINS_MODEL.carsharing.vehicle.details.logs, {
      chargingStatusLogData: state => state[VEHICLE_LOGS_SCOPES.chargeStatusLogs].data,
      chargingStatusLogLoading: state => state[VEHICLE_LOGS_SCOPES.chargeStatusLogs].STATUS.LOADING,
    }),
    chargingStatusSchema() {
      return CHARGE_STATUS_SCHEMA({
        timezone: this.operatorTimezone,
      });
    },
    chargingStatusExportSchema() {
      return [
        ...this.chargingStatusSchema,
        {
          header: 'License plate',
          template: () => this.licensePlate,
        },
      ];
    },
  },
  async created() {
    await this.getVehicleChargeStatusLog({ vehicleUuid: this.vehicleUuid });
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.carsharing.vehicle.details.logs, [
      'getVehicleChargeStatusLog',
    ]),
  },
};
</script>

<template>
  <div class="ChargingStatusLogsView">
    <h1 class="mb-2">
      Charging status
    </h1>
    <FilterTableComponent
      :schema="chargingStatusSchema"
      :export-schema="chargingStatusExportSchema"
      :data="chargingStatusLogData"
      :is-loading="chargingStatusLogLoading"
      exportable
    />
  </div>
</template>
<style lang="scss">
  .ChargingStatusLogsView {
    .ListToolbar {
      justify-content: flex-end !important;
    }
  }
</style>
