<script>
import get from 'lodash/get';
import { mapState } from 'vuex';
import { MuiAlgoliaList } from '@emobg/motion-ui';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { caseEventsFacets, contentCells } from './const/detailsContentCells';

export default {
  name: 'DetailsCasesView',
  components: {
    MuiAlgoliaList,
  },
  data() {
    return {
      vehicleUuid: get(this, '$route.params.vehicleUuid'),
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.carsharing.vehicle.details.information, {
      generalData: state => state.general.data,
    }),
    caseEventsFilters() {
      return `vehicle_uuid:${this.vehicleUuid}`;
    },
  },
  created() {
    this.CASES_INDEX = ALGOLIA_INDEXES.caseEvents;
    this.caseEventsFacets = caseEventsFacets;
    this.contentCells = contentCells.bind(this)();
  },
  methods: {
    redirectToCasesForm() {
      window.location.href = `/cases-service/case-events/create?entity=vehicle&entityUUID=${get(this.generalData, 'uuid')}`;
    },
  },
};
</script>

<template>
  <div class="DetailsCasesView">
    <div class="d-flex justify-content-between align-items-center mb-2">
      <h1>
        Cases
      </h1>

      <ui-button
        data-test-id="vehicle_cases_tab-create-button"
        @clickbutton="redirectToCasesForm"
      >
        Create case
      </ui-button>
    </div>

    <MuiAlgoliaList
      :export-columns="contentCells"
      :facets="caseEventsFacets"
      :filters="caseEventsFilters"
      :index="CASES_INDEX"
      :table-config="contentCells"
      is-export-enabled
      is-sidebar-collapsed
    />
  </div>
</template>
