<script>
import get from 'lodash/get';
import { mapActions, mapMutations, mapState } from 'vuex';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { CommentsList } from '@/components';
import { COMMENTABLE_TYPES } from '@/components/Comments/v1/constants/comments';

export default {
  name: 'DetailsCommentsView',
  components: {
    CommentsList,
  },
  data() {
    return {
      vehicleUuid: get(this, '$route.params.vehicleUuid'),
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.comments, {
      commentsData: state => get(state, 'comments.data') || [],
      commentsStatus: state => state.comments.STATUS,
    }),
  },
  created() {
    this.getCommentsByUuid(this.vehicleUuid);
  },
  methods: {
    get,
    ...mapActions(DOMAINS_MODEL.app.comments, [
      'getCommentsByType',
    ]),
    ...mapMutations(DOMAINS_MODEL.app.comments, [
      'clearData',
    ]),
    async getCommentsByUuid() {
      this.clearData();
      await this.getCommentsByType({
        commentableUuid: this.vehicleUuid,
        commentableType: COMMENTABLE_TYPES.vehicle,
      });
    },
  },
};
</script>

<template>
  <div class="DetailsCommentsView">
    <CommentsList
      :comments="commentsData"
      :is-loading="commentsStatus.LOADING"
      :entity-uuid="vehicleUuid"
      :callback="getCommentsByUuid"
      entity="vehicle"
      class="mb-4"
    />
  </div>
</template>
