import { FALLBACK_MESSAGE, isFalsyString, sentenceCase } from '@emobg/web-utils';
import { DAMAGE_BLUEPRINT_SIDE_NAMES } from '../../../VehicleDamages/damages.const';

export const facets = [
  {
    type: 'refinementList',
    props: {
      title: 'Vehicle side',
      attributeName: 'side',
      transformValue: side => DAMAGE_BLUEPRINT_SIDE_NAMES[side].label,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Damage status',
      attributeName: 'status',
      transformValue: sentenceCase,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Investigation status',
      attributeName: 'investigation.status',
      transformValue: value => isFalsyString(value) ? FALLBACK_MESSAGE.dash : sentenceCase(value),
    },
  },
];
