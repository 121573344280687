var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "DamagesComponent row" },
    _vm._l(_vm.transformDamages, function (side) {
      return _c(
        "div",
        { key: side.name, staticClass: "col-md-3" },
        [
          _c("DamageSide", {
            attrs: {
              src: side.url,
              label: side.label,
              alt: side.name,
              dots: _vm.mapArray(side.damages, (damage) => ({
                x: damage.coordinate_x,
                y: damage.coordinate_y,
              })),
            },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }