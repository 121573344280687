<script>
import filter from 'lodash/filter';
import get from 'lodash/get';
import has from 'lodash/has';
import head from 'lodash/head';
import includes from 'lodash/includes';
import intersection from 'lodash/intersection';
import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';
import {
  DATE_FORMAT,
  formatThousands,
  formatUtc,
  isFalsyString,
  navigationErrorHandler,
  TIME_ZONE,
  titleCase,
} from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import fleet from '@domains/Fleet/router/FleetRouterMap';
import {
  ConnectivityStatusComponent,
  ContentCellComponent,
  OverviewCollapse,
  PageTabs,
  PageView,
  PermissionLink,
} from '@/components';
import BatteryLevel from '../../components/BatteryLevel/BatteryLevel';
import ChargingStatus from '../components/ChargingStatus/ChargingStatus';
import VehicleLocation from '../components/VehicleLocation/VehicleLocation';

import { CARSHARING_PERMISSIONS } from '../../const/permissions';
import { VEHICLE_TABS } from './const/tabs.const';
import FUEL_TYPES from '../const/fuelTypes.const';
import fleetRoutes from '../../router/FleetRouterMap';

export default {
  name: 'DetailsView',
  components: {
    BatteryLevel,
    ChargingStatus,
    ConnectivityStatusComponent,
    ContentCellComponent,
    OverviewCollapse,
    PageTabs,
    PageView,
    PermissionLink,
    VehicleLocation,
  },
  async beforeRouteUpdate(to, _from, next) {
    next();
    if (this.vehicleUuid !== to.params.vehicleUuid) {
      await this.initialLoad();
    }
  },
  beforeRouteLeave(_to, _from, next) {
    // Remove vehicle in the store so when we enter again it will not appear the old vehicle loaded
    this.setVehicleData({
      value: [],
      scope: 'general',
    });
    next();
  },
  data() {
    return {
      tabs: [],
      areTabsReady: false,
      isAlertListShown: false,
      visibleAlertsCount: 2,
      vehicleUuid: get(this, '$route.params.vehicleUuid'),
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.carsharing.vehicle.details.information, {
      isGpsRequestLoading: state => state.position.STATUS.LOADING,
      isLoaded: state => state.general.STATUS.LOADED,
      generalData: state => state.general.data,
      alerts: state => state.general.data.alerts,
      address: state => state.general.data.allocation.address || state.general.data.allocation.internal_address,
    }),
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorTimezone: state => state.operators.active.timezone || TIME_ZONE.default,
      userPermissions: state => get(state, 'user.data.permissions', []),
    }),
    ...mapGetters(DOMAINS_MODEL.app.userAccount, [
      'hasPermissions',
    ]),
    isFullListVisible() {
      return this.isAlertListShown && this.alerts.length > this.visibleAlertsCount;
    },
    ...mapGetters(DOMAINS_MODEL.app.userAccount, [
      'hasPermissions',
    ]),
    isElectric() {
      return get(this.generalData, 'model.fuel_type') === FUEL_TYPES.electric;
    },
    isNonConnected() {
      return get(this.generalData, 'non_connected');
    },
  },
  async created() {
    this.CARSHARING_PERMISSIONS = CARSHARING_PERMISSIONS;
    this.DATE_FORMAT = DATE_FORMAT;
    this.TIME_ZONE = TIME_ZONE;
    this.fleet = fleet;
    await this.initialLoad();
  },
  methods: {
    get,
    has,
    formatThousands,
    formatUtc,
    ...mapMutations(DOMAINS_MODEL.carsharing.vehicle.details.information, {
      setVehicleData: 'setData',
    }),
    ...mapActions(DOMAINS_MODEL.carsharing.vehicle.details.information, ['getVehicleDetails', 'getGpsPosition']),
    async initialLoad() {
      this.vehicleUuid = this.$route.params.vehicleUuid;
      await this.getVehicleDetails(this.vehicleUuid);
      this.updateTabsVisibility();
    },
    updateTabsVisibility() {
      this.areTabsReady = false;
      this.tabs = filter(VEHICLE_TABS, item => {
        const matchesPermissions = intersection(this.userPermissions, item.permissions).length;

        return this.isNonConnected
          ? matchesPermissions && !includes([fleetRoutes.vehicles.detail.interactions, fleetRoutes.vehicles.detail.logs.index], item.url)
          : matchesPermissions;
      });

      const filteredTabs = filter(this.tabs, tab => {
        const routeTab = this.$router.resolve({
          name: tab.url,
        });

        return includes(get(this, '$route.fullPath', ''), get(routeTab, 'route.fullPath'));
      });

      if (!filteredTabs.length) {
        const firstTab = get(head(this.tabs), 'url');
        this.$router.push({ name: firstTab }).catch(navigationErrorHandler);
      }

      this.areTabsReady = true;
    },
    getIconColor(iconData) {
      return get(this.generalData, iconData) ? 'black' : 'ink-light';
    },
    titleCase,
    isFalsyString,
  },
};
</script>

<template>
  <div class="DetailsView">
    <PageView
      v-if="generalData"
      class="emobg-background-color-white"
    >
      <OverviewCollapse id="vehicleOverview">
        <template #header>
          <div class="d-flex align-items-center">
            <span class="emobg-font-large emobg-font-weight-semibold mr-2">
              {{ generalData.license_plate }}
            </span>
            <span class="emobg-font-large emobg-font-weight-semibold mr-2">
              {{ get(generalData, 'model.brand') }}
              {{ get(generalData, 'model.name') }}
            </span>
            <span class="emobg-caption-1 emobg-color-ink-light">
              {{ get(generalData, 'category.name') }}
            </span>
          </div>
        </template>
        <div class="row py-4">
          <div class="col-md-6 col-lg-3">
            <img
              :src="get(generalData, 'model.image')"
              :alt="get(generalData, 'model.brand')"
              class="w-75 pr-3 mb-4 vehicle__image align-bottom"
            >
            <h3 class="mb-1">
              {{ generalData.license_plate }}
            </h3>
            <div class="d-flex align-items-center emobg-body-2 mb-1">
              <ui-icon
                v-if="isElectric"
                :icon="ICONS.electric"
                class="electric-vehicle-icon-color"
              />
              {{ get(generalData, 'model.brand') }}
              {{ get(generalData, 'model.name') }}
              {{ generalData.color ? `(${ titleCase(generalData.color) })` : FALLBACK_MESSAGE.noData }}
            </div>
            <div class="emobg-color-ink-light mb-4">
              <p class="mb-1">
                {{ get(generalData, 'category.name') }}
              </p>
              <p v-if="isElectric">
                Traction battery capacity:
                {{ get(generalData, 'model.fuel_capacity') ? `${get(generalData, 'model.fuel_capacity')}kW` : FALLBACK_MESSAGE.noData }}
              </p>
            </div>
            <p class="mb-4">
              <span class="emobg-body-2">UUID:</span>
              <br>
              <span class="emobg-body-1">{{ generalData.uuid }}</span>
            </p>
          </div>
          <div class="col-md-6 col-lg-3">
            <ContentCellComponent
              label="Connectivity"
              class="mb-4"
              is-on-header
            >
              <ConnectivityStatusComponent
                :status="get(generalData, 'telemetry.connectivity_status')"
                :icon-size="ICONS_SIZES.large"
                :is-non-connected="isNonConnected"
                show-tooltip
                class="my-2 emobg-body-2"
              />
              <div
                v-if="get(generalData, 'telemetry.connectivity_date')"
                class="emobg-body-1 emobg-color-ink-light"
              >
                Updated: {{ formatUtc(generalData.telemetry.connectivity_date, DATE_FORMAT.defaultExtended, operatorTimezone) }}
              </div>
            </ContentCellComponent>
            <template v-if="!isNonConnected">
              <ContentCellComponent
                :label="isElectric ? 'Traction battery' : 'Fuel level'"
                class="mb-4"
                is-on-header
              >
                <div
                  :class="[
                    'd-flex align-items-center mb-1 v-body-1',
                    `emobg-color-${getIconColor('fuel_level')}`,
                  ]"
                >
                  <div class="emobg-body-2">
                    <BatteryLevel
                      v-if="isElectric"
                      :battery-level="get(generalData, 'fuel_level')"
                      :danger-level="get(generalData, 'model.fuel_danger_level', 0)"
                      :icon-size="ICONS_SIZES.xLarge"
                    />
                    <template v-else>
                      <ui-icon
                        :icon="ICONS.fuelDispenser"
                        :color="getIconColor('fuel_level')"
                        class="mr-2"
                      />
                      <span>
                        {{ get(generalData, 'fuel_level') ? `${generalData.fuel_level}%` : FALLBACK_MESSAGE.noData }}
                      </span>
                    </template>
                  </div>
                </div>
                <p
                  v-if="generalData.fuel_level_timestamp"
                  class="emobg-body-1 emobg-color-ink-light mt-1"
                >
                  Updated: {{ formatUtc(generalData.fuel_level_timestamp, DATE_FORMAT.defaultExtended, operatorTimezone) }}
                </p>
                <template v-if="isElectric">
                  <div class="mt-1">
                    <ChargingStatus :status="get(generalData, 'telemetry.charging_status')" />
                  </div>
                  <p
                    v-if="has(generalData, 'telemetry.charging_status_date')"
                    class="emobg-body-1 emobg-color-ink-light mt-1"
                  >
                    Updated: {{ formatUtc(generalData.telemetry.charging_status_date, DATE_FORMAT.defaultExtended, operatorTimezone) }}
                  </p>
                </template>
              </ContentCellComponent>
              <ContentCellComponent
                label="Battery level"
                class="mb-4"
              >
                <div
                  :class="[
                    'd-flex align-items-center mb-1',
                    `emobg-color-${getIconColor('battery_level')}`,
                  ]"
                >
                  <ui-icon
                    :icon="ICONS.carBattery"
                    :color="getIconColor('battery_level')"
                    class="mr-2"
                  />
                  <div class="emobg-body-2">
                    {{ generalData.battery_level ? `${generalData.battery_level}V`: FALLBACK_MESSAGE.noData }}
                  </div>
                </div>
                <span
                  v-if="generalData.battery_level_timestamp"
                  class="emobg-body-1 emobg-color-ink-light mt-1"
                >
                  Updated: {{ formatUtc(generalData.battery_level_timestamp, DATE_FORMAT.defaultExtended, operatorTimezone) }}
                </span>
              </ContentCellComponent>
            </template>
          </div>
          <div class="col-md-6 col-lg-3">
            <ContentCellComponent
              label="Status"
              class="mb-4"
              is-on-header
            >
              <ui-badge
                :text="generalData.active ? 'Active' : 'Inactive'"
                :color="generalData.active ? COLORS.success : COLORS.danger"
              />
            </ContentCellComponent>

            <template v-if="!isNonConnected">
              <ContentCellComponent
                label="Current mileage"
                class="mb-4"
                is-on-header
              >
                <div
                  :class="[
                    'd-flex align-items-center mb-1',
                    `emobg-color-${getIconColor('mileage.value')}`,
                  ]"
                >
                  <ui-icon
                    :icon="ICONS.miles"
                    :color="getIconColor('mileage.value')"
                    class="mr-2"
                  />
                  <div class="emobg-body-2">
                    <span v-if="get(generalData, 'mileage.value')">
                      {{ formatThousands(generalData.mileage.value) }} {{ get(generalData, 'mileage.unit') }}
                    </span>
                    <span v-else>{{ FALLBACK_MESSAGE.noData }}</span>
                  </div>
                </div>
              </ContentCellComponent>
              <ContentCellComponent
                label="Alerts"
                class="mb-4"
                is-on-header
              >
                <template v-if="alerts && alerts.length">
                  <div
                    v-for="(alert, index) in alerts.slice(0, visibleAlertsCount)"
                    :key="index"
                    class="emobg-body-1 py-1"
                  >
                    <ui-badge
                      :color="COLORS.warning"
                      solid
                      circle
                      class="mr-2"
                    /> {{ alert }}
                  </div>
                  <ui-button
                    v-if="alerts.length > visibleAlertsCount"
                    :face="FACES.text"
                    class="ml-n3"
                    @clickbutton="isAlertListShown = true"
                  >
                    Show all ({{ alerts.length }})
                  </ui-button>
                  <div
                    v-if="isFullListVisible"
                    class="emobg-border-1 emobg-border-color-ground-light emobg-background-color-white
                      DetailsView__alerts-list position-relative p-2 mt-n6 ml-n3 shadow-s"
                  >
                    <ui-button
                      :face="FACES.text"
                      square
                      class="float-right"
                      @clickbutton="isAlertListShown = false"
                    >
                      <ui-icon
                        :icon="ICONS.cross"
                        :size="ICONS_SIZES.small"
                        :color="GRAYSCALE.ground"
                      />
                    </ui-button>
                    <div
                      v-for="(alert, i) in alerts"
                      :key="i"
                      class="emobg-caption-1 py-1"
                    >
                      <ui-badge
                        :color="COLORS.warning"
                        solid
                        circle
                        class="mr-2"
                      /> {{ alert }}
                    </div>
                  </div>
                </template>
                <div
                  v-else
                  class="d-flex align-items-center"
                >
                  <ui-badge
                    :color="GRAYSCALE.groundLight"
                    solid
                    circle
                    class="mr-2"
                  />
                  <span class="emobg-color-ink-light">
                    No alerts
                  </span>
                </div>
              </ContentCellComponent>
              <ContentCellComponent
                label="Home location"
                class="mb-4"
                is-on-header
              >
                <div
                  v-if="generalData.allocation"
                  class="text-elipsis emobg-body-1"
                >
                  <PermissionLink
                    v-if="generalData.allocation.id && generalData.allocation.name"
                    :to="{
                      name: fleet.locations.detail.index,
                      params: {
                        locationUuid: generalData.allocation.uuid,
                      },
                    }"
                    :link-permissions="[CARSHARING_PERMISSIONS.viewCarsharingLocations]"
                    class-link="emobg-link-primary emobg-body-2"
                  >
                    {{ generalData.allocation.name }}
                  </PermissionLink>
                  <div>{{ address || FALLBACK_MESSAGE.noData }}, {{ generalData.allocation.neighborhood || FALLBACK_MESSAGE.noData }}</div>
                  <div>{{ generalData.allocation.city || FALLBACK_MESSAGE.noData }}</div>
                </div>
                <ui-badge
                  v-else
                  :text="FALLBACK_MESSAGE.noData"
                  :color="GRAYSCALE.ground"
                />
              </ContentCellComponent>
            </template>
          </div>
          <div
            v-if="!isNonConnected"
            class="col-md-auto d-flex flex-column align-items-center"
          >
            <VehicleLocation v-if="isLoaded" />
          </div>
        </div>
      </OverviewCollapse>
    </PageView>
    <PageTabs
      v-if="isLoaded && areTabsReady"
      :tabs="tabs"
      has-overview
    />
    <PageView
      v-if="isLoaded"
      is-tab-content
    >
      <Transition name="page">
        <RouterView />
      </Transition>
    </PageView>
  </div>
</template>
