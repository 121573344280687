import {
  TIME_ZONE,
  formatStopWatch,
  sentenceCase,
} from '@emobg/web-utils';

export function bookingListFacets() {
  return [
    {
      type: 'DateIntervalsRange',
      props: {
        title: 'Dates',
        dateStart: 'start_ts',
        dateEnd: 'end_ts',
        labels: {
          from: 'From',
          to: 'To',
          clearButton: 'Clear dates',
          none: 'None',
          rangeOverlap: 'Active bookings',
          dateEnd: 'Booking end date',
          dateStart: 'Booking start date',
        },
        timezone: this.operatorTimezone || TIME_ZONE.default,
        from: null,
        to: null,
      },
    },
    {
      type: 'rangeslider',
      props: {
        title: 'Booking duration',
        attributeName: 'duration_in_secs',
        toggled: false,
        showTooltip: true,
        format: value => ((value < 60) ? '0 min' : formatStopWatch(value)),
        pipValues: [],
      },
    },
    {
      type: 'rangeslider',
      props: {
        title: 'Real duration',
        attributeName: 'real_duration_in_secs',
        toggled: false,
        showTooltip: true,
        format: value => ((value < 60) ? '0 min' : formatStopWatch(value)),
        pipValues: [],
      },
    },
    {
      type: 'refinementList',
      props: {
        title: 'Profile',
        attributeName: 'profile',
        toggled: false,
        transformValue: sentenceCase,
      },
    },
    {
      type: 'refinementList',
      props: {
        title: 'Alerts',
        attributeName: 'alert_reason',
        toggled: false,
        showTooltip: true,
      },
    },
    {
      type: 'refinementList',
      props: {
        title: 'Operator',
        attributeName: 'cs_operator',
        toggled: false,
      },
    },
    {
      type: 'refinementList',
      props: {
        title: 'City',
        attributeName: 'city',
        toggled: false,
      },
    },
    {
      type: 'refinementList',
      props: {
        title: 'Location',
        attributeName: 'location',
        toggled: false,
        showTooltip: true,
        transformValue: sentenceCase,
      },
    },
    {
      type: 'refinementList',
      props: {
        title: 'System status',
        attributeName: 'status',
        transformValue: sentenceCase,
        toggled: false,
        icon: {
          icon: 'system-status',
          color: 'main',
          size: '16',
        },
      },
    },
    {
      type: 'refinementList',
      props: {
        title: 'Usage status',
        attributeName: 'usage_status',
        transformValue: sentenceCase,
        toggled: false,
        icon: {
          icon: 'status',
          color: 'main',
          size: '16',
        },
      },
    },
    {
      type: 'refinementList',
      props: {
        title: 'Booking type',
        attributeName: 'booking_type',
        toggled: false,
        transformValue: sentenceCase,
      },
    },
    {
      type: 'refinementList',
      props: {
        title: 'Unavailability',
        attributeName: 'is_unavailability',
        toggled: false,
        transformValue: sentenceCase,
      },
    },
  ];
}
