<script>
import concat from 'lodash/concat';
import find from 'lodash/find';
import get from 'lodash/get';
import head from 'lodash/head';
import includes from 'lodash/includes';
import isBoolean from 'lodash/isBoolean';
import isFunction from 'lodash/isFunction';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import snakeCase from 'lodash/snakeCase';
import each from 'lodash/each';
import moment from 'moment-timezone';
import { mapActions, mapMutations, mapState } from 'vuex';
import { MuiAlgoliaList } from '@emobg/motion-ui';
import { MuiValidationWrapper } from '@emobg/motion-ui/v1';
import {
  camelCaseKeys,
  DATE_FORMAT,
  formatThousands,
  formatUtc,
  reformatDateTime,
  sentenceCase,
} from '@emobg/web-utils';
import { BUTTON_TYPES, MuiCard } from '@emobg/vue-base';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import crm from '@domains/CRM/router/CRMRouterMap';
import fleetRouter from '@domains/Fleet/router/FleetRouterMap';
import { fromCentsToDecimals, fromDecimalToCents } from '@domains/Pricing/utils/pricing.utils';
import {
  CancelButton,
  ContentCellComponent,
  EditButton,
  GenericModalComponent,
  MarkdownComponent,
  StoreNotificationComponent,
} from '@/components';

import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { refreshAlgoliaStore } from '@/utils';
import { JURIDIC_STATUS_COLORS } from './components/const/editVehicleComponents';
import { allocationContentCells } from './config/allocationContentCells';
import { vehicleInformationCells } from './config/vehicleInformation';
import {
  EditDescriptionComponent,
  EditEquipmentComponent,
  EditFinancialInfoComponent,
  EditGeneralInfoComponent,
  EditHardwareComponent,
  EditStatusComponent,
} from './components';
import {
  RemoveVehicleAllocationFormComponent,
  VehicleAllocationFormComponent,
} from '../../../Availability/components';
import { EDIT_SECTIONS } from './const/sections';
import { carsharing as carsharingErrors } from '../../../const/errorKeys';
import { FLEETS } from '../../const/vehicles.const';
import { scopes } from './store/SetupModule';

export default {
  name: 'DetailsSetupView',
  components: {
    CancelButton,
    GenericModalComponent,
    EditButton,
    EditDescriptionComponent,
    EditEquipmentComponent,
    EditFinancialInfoComponent,
    EditGeneralInfoComponent,
    EditHardwareComponent,
    EditStatusComponent,
    MarkdownComponent,
    MuiAlgoliaList,
    MuiCard,
    MuiValidationWrapper,
    VehicleAllocationFormComponent,
    RemoveVehicleAllocationFormComponent,
    ContentCellComponent,
    StoreNotificationComponent,
  },
  data() {
    return {
      isFormValid: false,
      hasSameValues: true,
      vehicleUuid: get(this, '$route.params.vehicleUuid'),
      modalHeader: {},
      isModalVisible: false,
      editSection: '',
      apiPayload: {},
      isVehicleAllocationFormVisible: false,
      initialVehicleAllocationData: null,
      vehicleAllocation: null,
      isRemoveModalVisible: false,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      permissions: state => get(state, 'user.data.permissions'),
      operatorTimezone: state => state.operators.active.timezone,
    }),
    ...mapState(DOMAINS_MODEL.carsharing.vehicle.details.setup, {
      equipmentData: state => state.equipments.data,
      equipmentStatus: state => state.equipments.STATUS,
      descriptionsData: state => camelCaseKeys(state.descriptions.data.descriptions),
      descriptionsStatus: state => state.descriptions.STATUS,
      salesOrder: state => get(state, 'salesOrder.data.data'),
      salesOrderLoading: state => state.salesOrder.STATUS.LOADING,
    }),
    ...mapState(DOMAINS_MODEL.carsharing.vehicle.details.information, {
      financialData: state => concat(state.general.data.financial_info),
      information: state => state.general.data,
      hardware: state => state.general.data.hardware,
      isActive: state => state.general.data.active,
      isNonConnected: state => state.general.data.non_connected,
      isVisible: state => state.general.data.api,
      isConnectedCarPlatform: state => !!state.general.data.is_connected_car_platform,
      vehicleGeneralStatus: state => state.general.STATUS,
      isDedicatedFleet: state => get(state, 'general.data.cs_operator_fleet_type') === FLEETS.dedicated,
    }),
    cloudBoxxDeviceId() {
      return get(this.information, 'cloudboxx_device_id');
    },
    allowSecondaryFuelCard() {
      return includes(this.permissions, 'allow_secondary_fuelcard');
    },
    visibilityLabel() {
      return this.isVisible ? 'visible' : 'invisible';
    },
    activityLabel() {
      return this.isActive ? 'active' : 'inactive';
    },
    deviceStatusLabel() {
      return get(this, 'hardware.device_status') ? 'Online' : 'Offline';
    },
    checkedItems() {
      return this.equipmentData && this.equipmentData.filter(item => item.checked);
    },
    isModalLoading() {
      return this.vehicleGeneralStatus.LOADING || this.equipmentStatus.LOADING || this.descriptionsStatus.LOADING;
    },
    status() {
      return {
        api: this.isVisible,
        active: this.isActive,
      };
    },
    salesOrderStartDate() {
      const startDate = get(this.salesOrder, 'currentSalesOrder.startDate');
      return startDate ? reformatDateTime(startDate, DATE_FORMAT.dob) : this.FALLBACK_MESSAGE.dash;
    },
    salesOrderEndDate() {
      const endDate = get(this.salesOrder, 'currentSalesOrder.endDate');
      return endDate ? reformatDateTime(endDate, DATE_FORMAT.dob) : this.FALLBACK_MESSAGE.dash;
    },
    isIboxxWithoutDevice() {
      return !this.isConnectedCarPlatform && !this.hardware.device_uuid;
    },
    isValidatedForm() {
      const isActiveIboxx = this.isActive && !get(this, 'apiPayload.is_connected_car_platform', true) && !this.isNonConnected;
      return isActiveIboxx ? this.isFormValid && get(this, 'apiPayload.device_uuid') : this.isFormValid;
    },
  },
  async mounted() {
    const initRequests = [
      this.getEquipments(this.vehicleUuid),
      this.getDescriptions(this.vehicleUuid),
    ];

    if (this.isDedicatedFleet) {
      initRequests.push(this.getSalesOrderVehicle(this.vehicleUuid));
    }

    await Promise.all(initRequests);
  },
  created() {
    this.FLEETS = FLEETS;
    this.JURIDIC_STATUS_COLORS = JURIDIC_STATUS_COLORS;
    this.DATE_FORMAT = DATE_FORMAT;
    this.EDIT_SECTIONS = EDIT_SECTIONS;
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.DOMAINS_MODEL = DOMAINS_MODEL;
    this.BUTTON_TYPES = BUTTON_TYPES;
    this.crm = crm;
    this.fleetRouter = fleetRouter;
    this.reformatDateTime = reformatDateTime;
    this.scopes = scopes;
    this.centsKeys = ['rent_amount_without_vat', 'bonus_amount', 'first_rent_amount_without_vat'];
    this.carsharingErrors = carsharingErrors;
    this.contentCells = allocationContentCells(this.operatorTimezone);
    this.vehicleInformationCells = vehicleInformationCells;
    this.allocationItemsActions = [
      {
        label: 'Edit',
        method: result => this.openVehicleAllocationForm({
          uuid: result.uuid,
          start: reformatDateTime(result.start, DATE_FORMAT.dateTime, this.operatorTimezone),
          end: reformatDateTime(result.end, DATE_FORMAT.dateTime, this.operatorTimezone),
          location_uuid: result.location_uuid,
          city_uuid: result.location_city_uuid,
          vehicle_uuid: result.vehicle_uuid,
        }),
        isVisible: result => {
          const now = moment.utc();
          const end = moment.utc(result.end, DATE_FORMAT.dateTime);

          return now.isBefore(end);
        },
      },
      {
        label: 'Delete',
        type: 'danger',
        method: result => this.openRemoveVehicleAllocationModal({
          uuid: result.uuid,
        }),
        isVisible: result => {
          const now = moment.utc();
          const start = moment.utc(result.start, DATE_FORMAT.dateTime);

          return now.isBefore(start);
        },
      },
    ];
  },
  methods: {
    get,
    head,
    includes,
    isBoolean,
    isNumber,
    isString,
    snakeCase,
    formatUtc,
    formatThousands,
    fromCentsToDecimals,
    refreshAlgoliaStore,
    ...mapActions(DOMAINS_MODEL.carsharing.vehicle.details.setup, [
      'getEquipments',
      'getDescriptions',
      'patchVehicleDetails',
      'putEquipments',
      'getSalesOrderVehicle',
    ]),
    ...mapActions(DOMAINS_MODEL.carsharing.vehicle.details.information, [
      'getVehicleDetails',
    ]),
    ...mapMutations(DOMAINS_MODEL.carsharing.vehicle.details.setup, [
      'clearErrors',
    ]),
    closeVehicleAllocationModal() {
      this.vehicleAllocation = null;
      this.initialVehicleAllocationData = null;
      this.isVehicleAllocationFormVisible = false;
      this.isRemoveModalVisible = false;
    },
    expiryDate(date) {
      return date ? reformatDateTime(date, 'YYYY-MM') : null;
    },
    openVehicleAllocationForm(vehicleAllocation, initial = null) {
      this.vehicleAllocation = vehicleAllocation;
      this.initialVehicleAllocationData = initial;
      this.isVehicleAllocationFormVisible = true;
    },
    openRemoveVehicleAllocationModal(vehicleAllocation) {
      this.vehicleAllocation = vehicleAllocation;
      this.isRemoveModalVisible = true;
    },
    refreshAllocationIndex() {
      this.closeVehicleAllocationModal();
      this.refreshAlgoliaStore(this.$refs.allocationsList, 2000);
    },
    editVehicle(section) {
      this.editSection = section;
      this.modaTitle = sentenceCase(section);
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
      this.apiPayload = {};
    },
    async confirmAction() {
      this.clearErrors({ scope: scopes.descriptions });
      if (this.editSection === EDIT_SECTIONS.equipment) {
        await this.putEquipments({ vehicleUuid: this.vehicleUuid, data: this.apiPayload });
      } else {
        const data = { ...this.apiPayload };
        each(this.centsKeys, key => {
          if (data[key]) {
            data[key] = fromDecimalToCents(data[key]);
          }
        });
        await this.patchVehicleDetails({ vehicleUuid: this.vehicleUuid, data });
      }

      if (!this.descriptionsStatus.ERROR) {
        await Promise.all([
          this.getDescriptions(this.vehicleUuid),
          this.getVehicleDetails(this.vehicleUuid),
        ]);
        this.closeModal();
      }
    },
    transformContentCell(cell, scope = this.information) {
      if (!cell) {
        return this.FALLBACK_MESSAGE.dash;
      }

      const { value } = cell;

      return isFunction(value)
        ? value(scope)
        : get(scope, value) || this.FALLBACK_MESSAGE.dash;
    },
    sentenceCase,
    getJuridicStatusColor(status) {
      return find(this.JURIDIC_STATUS_COLORS, (key, value) => status === value);
    },
  },
};
</script>

<template>
  <div class="DetailsSetupView">
    <h1 class="mb-2">
      Vehicle setup
    </h1>

    <ui-card
      v-if="information"
      header="Vehicle information"
      class="w-100 d-block"
    >
      <div class="row">
        <div class="col-md-9">
          <div class="row">
            <div
              v-for="(cell, cellIndex) in vehicleInformationCells"
              :key="cellIndex"
              class="col-4 pb-4"
            >
              <p class="mb-2 emobg-font-weight-bold">
                {{ cell.label || sentenceCase(cell.value) }}
              </p>
              <p class="emobg-color-ink ellipsis text-capitalize">
                {{ transformContentCell(cell) }}
              </p>
            </div>
          </div>
        </div>

        <div class="col-md-3 d-flex justify-content-end">
          <EditButton @click="editVehicle(EDIT_SECTIONS.information)" />
        </div>
      </div>
    </ui-card>

    <ui-card
      header="Equipment"
      class="w-100 mt-4  d-block"
    >
      <div class="row">
        <div class="col-md-9">
          <div
            v-if="checkedItems && checkedItems.length"
            class="row"
          >
            <div
              v-for="item in checkedItems"
              :key="item.equipment_uuid"
              class="col-4 my-2"
            >
              <div
                :class="[
                  'd-inline-block',
                  `icons-${snakeCase(item.internal_name)}`
                ]"
              />
              {{ sentenceCase(item.name) }}
            </div>
          </div>
          <span v-else>{{ FALLBACK_MESSAGE.dash }}</span>
        </div>

        <div class="col-md-3 d-flex justify-content-end">
          <EditButton @click="editVehicle(EDIT_SECTIONS.equipment)" />
        </div>
      </div>
    </ui-card>

    <ui-card
      header="Description"
      class="w-100 mt-4 d-block"
    >
      <div class="row">
        <div class="col-md-9">
          <MarkdownComponent
            :content="descriptionsData"
            language="enGb"
          />
        </div>

        <div class="col-md-3 d-flex justify-content-end">
          <EditButton @click="editVehicle(EDIT_SECTIONS.description)" />
        </div>
      </div>
    </ui-card>
    <ui-card
      v-if="isDedicatedFleet"
      header="Corporate invoice sales order"
      class="w-100 mt-4 d-block position-relative hydrated"
    >
      <ui-loader
        v-show="salesOrderLoading"
        absolute
      />
      <div class="row">
        <ContentCellComponent
          label="CS operator"
          class="col-md-3 mb-3"
          :value="get(salesOrder, 'currentSalesOrder.companyName') || FALLBACK_MESSAGE.dash"
        />
        <ContentCellComponent
          label="Juridic status"
          class="col-md-3 mb-3"
        >
          <ui-badge
            v-if="get(salesOrder, 'status')"
            :color="getJuridicStatusColor(salesOrder.status)"
          >
            {{ sentenceCase(salesOrder.status) }}
          </ui-badge>
          <template v-else>
            {{ FALLBACK_MESSAGE.dash }}
          </template>
        </ContentCellComponent>
        <ContentCellComponent
          label="Sales order #"
          class="col-md-3 mb-3"
        >
          <RouterLink
            v-if="get(salesOrder, 'currentSalesOrder.companyUuid')"
            :to="{
              name: crm.companies.detail.corporateContract.salesOrders,
              params: {
                companyUuid: get(salesOrder, 'currentSalesOrder.companyUuid'),
                contractUuid: get(salesOrder, 'currentSalesOrder.contractUuid'),
                salesOrderUuid: get(salesOrder, 'currentSalesOrder.salesOrderUuid')
              },
            }"
            class="emobg-link-primary emobg-body-2"
          >
            {{ get(salesOrder, 'currentSalesOrder.salesOrderUuid', '').substr(0, 7) }}
          </RouterLink>
          <template v-else>
            {{ FALLBACK_MESSAGE.dash }}
          </template>
        </ContentCellComponent>
      </div>
      <div class="row">
        <ContentCellComponent
          label="Start date"
          class="col-md-3 mb-3"
        >
          {{ salesOrderStartDate }}
        </ContentCellComponent>
        <ContentCellComponent
          label="End date"
          class="col-md-3 mb-3"
        >
          {{ salesOrderEndDate }}
        </ContentCellComponent>
      </div>
    </ui-card>

    <MuiCard
      v-if="!isNonConnected"
      class="w-100 mt-4 d-block"
    >
      <div class="d-flex align-items-center emobg-border-bottom-2 emobg-border-color-ground-light pb-3 mb-3">
        <h3 class="flex-fill emobg-font-weight-semibold ">
          Vehicle allocation
        </h3>
        <ui-button
          @clickbutton="openVehicleAllocationForm(null, {
            vehicle_uuid: vehicleUuid,
            start: moment().add(1, 'hour'),
            end: moment('2050-12-31 00:00:00'),
          })"
        >
          Create new allocation
        </ui-button>
      </div>
      <MuiAlgoliaList
        ref="allocationsList"
        :filters="`vehicle_uuid: ${vehicleUuid}`"
        :index="ALGOLIA_INDEXES.vehicleLocation"
        :item-actions="allocationItemsActions"
        :query-parameters="{ hitsPerPage: 5 }"
        :table-config="contentCells"
        no-cache
      />
    </MuiCard>

    <ui-card
      header="Status"
      class="w-100 mt-4 d-block"
    >
      <div class="row">
        <div class="col-md-9">
          <div class="row">
            <div class="col-4">
              <div class="emobg-font-weight-bold pb-3">
                Vehicle activation status
              </div>
              <ui-badge
                v-if="isBoolean(isActive)"
                :color="isActive ? COLORS.success : COLORS.danger"
              >
                {{ sentenceCase(activityLabel) }}
              </ui-badge>
              <ui-badge
                v-else
                :color="GRAYSCALE.ground"
              >
                {{ FALLBACK_MESSAGE.noData }}
              </ui-badge>
            </div>
            <div
              v-if="!isNonConnected"
              class="col-4"
            >
              <div class="emobg-font-weight-bold pb-3">
                Vehicle visibility status
              </div>
              <ui-badge
                v-if="isBoolean(isVisible)"
                :color="isVisible ? COLORS.success : COLORS.danger"
              >
                {{ sentenceCase(visibilityLabel) }}
              </ui-badge>
              <ui-badge
                v-else
                :color="GRAYSCALE.ground"
              >
                {{ FALLBACK_MESSAGE.noData }}
              </ui-badge>
            </div>
          </div>
        </div>

        <div class="col-md-3 d-flex justify-content-end">
          <EditButton @click="editVehicle(EDIT_SECTIONS.status)" />
        </div>
      </div>
    </ui-card>

    <ui-card
      v-if="hardware && !isNonConnected"
      header="Hardware"
      class="w-100 mt-4 d-block"
    >
      <div class="row">
        <div class="col-md-9">
          <div class="row pb-5">
            <h3 class="col-12 pb-3">
              Device
            </h3>
            <div class="col-4">
              <div class="emobg-font-weight-bold mb-2">
                Device type
              </div>
              <div v-text="isConnectedCarPlatform ? 'Connected Car platform device' : 'iBoxx'" />
            </div>
            <div class="col-4">
              <div class="emobg-font-weight-bold mb-2">
                Device number
              </div>
              <span
                v-if="isConnectedCarPlatform && cloudBoxxDeviceId"
                v-text="cloudBoxxDeviceId"
              />
              <RouterLink
                v-else-if="!isConnectedCarPlatform && hardware.device_id"
                :to="{ name: fleetRouter.hardware.devices, query: { query: hardware.device_number } }"
                class="emobg-link-primary emobg-body-2"
              >
                {{ hardware.device_number }}
              </RouterLink>
              <span v-else>{{ FALLBACK_MESSAGE.dash }}</span>
            </div>
          </div>
          <div class="row pb-5">
            <h3 class="col-12 pb-3">
              Fuel card
            </h3>
            <div class="col-4">
              <div class="emobg-font-weight-bold mb-2">
                Fuel card
              </div>
              {{ hardware.fuel_card_number || FALLBACK_MESSAGE.dash }}
            </div>
            <div class="col-4">
              <div class="emobg-font-weight-bold mb-2">
                Fuel card PIN
              </div>
              {{ hardware.fuel_card_pin || FALLBACK_MESSAGE.dash }}
            </div>

            <div class="col-4">
              <div class="emobg-font-weight-bold mb-2">
                Expiry date
              </div>
              {{ expiryDate(hardware.fuel_card_expiry_date) || FALLBACK_MESSAGE.dash }}
            </div>
          </div>

          <div
            v-if="allowSecondaryFuelCard"
            class="row pb-5"
          >
            <h3 class="col-12 pb-3">
              Secondary fuel card
            </h3>
            <div class="col-4">
              <div class="emobg-font-weight-bold mb-2">
                Secondary fuel card
              </div>
              {{ hardware.secondary_fuel_card_number || FALLBACK_MESSAGE.dash }}
            </div>
            <div class="col-4">
              <div class="emobg-font-weight-bold mb-2">
                Secondary fuel card PIN
              </div>
              {{ hardware.secondary_fuel_card_pin || FALLBACK_MESSAGE.dash }}
            </div>

            <div class="col-4">
              <div class="emobg-font-weight-bold mb-2">
                Expiry date
              </div>
              {{ expiryDate(hardware.secondary_fuel_card_expiry_date) || FALLBACK_MESSAGE.dash }}
            </div>
          </div>
        </div>

        <div class="col-md-3 d-flex justify-content-end">
          <EditButton @click="editVehicle(EDIT_SECTIONS.hardware)" />
        </div>
      </div>
    </ui-card>

    <ui-card
      v-if="financialData"
      header="Financial information"
      class="w-100 mt-4 d-block"
    >
      <div class="row">
        <div class="col-md-9">
          <div
            v-for="(item, index) in financialData"
            :key="index"
            class="row"
          >
            <div
              v-for="(mode, label) in item"
              :key="label"
              class="col-4 pb-4"
            >
              <div class="mb-2 emobg-font-weight-bold">
                {{ sentenceCase(label) }}
              </div>
              <p class="color-ink">
                <template v-if="isString(mode) && includes(label, '_uuid')">
                  {{ mode }}
                </template>
                <template v-else-if="isString(mode)">
                  {{ sentenceCase(mode) }}
                </template>
                <template v-else-if="isBoolean(mode)">
                  {{ mode ? 'Yes' : 'No' }}
                </template>
                <template v-else-if="isNumber(mode) && includes(label, '_date')">
                  {{ formatUtc(mode, DATE_FORMAT.dob) }}
                </template>
                <template v-else-if="includes(centsKeys, label) && mode">
                  {{ fromCentsToDecimals(mode, true) }}
                </template>
                <template v-else-if="isNumber(mode) && !includes(label, '_date')">
                  {{ formatThousands(mode) }}
                </template>
                <template v-else>
                  {{ mode || FALLBACK_MESSAGE.dash }}
                </template>
              </p>
            </div>
          </div>
        </div>

        <div class="col-md-3 d-flex justify-content-end">
          <EditButton @click="editVehicle(EDIT_SECTIONS.financial)" />
        </div>
      </div>
    </ui-card>
    <ui-form @submitform="confirmAction">
      <GenericModalComponent
        v-if="isModalVisible"
        :title="modaTitle"
        :header="{ isClosable: true }"
        class="VehicleFormComponent"
        @close-modal="closeModal"
        @modal-closed="closeModal"
        v-on="$listeners"
      >
        <template slot="alerts">
          <StoreNotificationComponent
            :store-domain="DOMAINS_MODEL.carsharing.vehicle.details.setup"
            :scope="scopes.descriptions"
            :custom-exceptions="[carsharingErrors.chassisAlreadyExists]"
            action="edit vehicle"
          />
        </template>
        <template slot="body">
          <MuiValidationWrapper
            id="EditVehicleForm"
            @areAllValid="isValid => isFormValid = isValid"
          >
            <EditStatusComponent
              v-if="editSection === EDIT_SECTIONS.status"
              :status="status"
              :is-non-connected="isNonConnected"
              :is-iboxx-without-device="isIboxxWithoutDevice"
              @update="apiPayload = $event"
              @update:payload="value => hasSameValues = value"
            />
            <EditDescriptionComponent
              v-if="editSection === EDIT_SECTIONS.description"
              :descriptions="descriptionsData"
              @update="apiPayload = $event"
              @update:payload="value => hasSameValues = value"
            />
            <EditEquipmentComponent
              v-if="editSection === EDIT_SECTIONS.equipment"
              :equipment="equipmentData"
              @update="apiPayload = $event"
              @update:payload="value => hasSameValues = value"
            />
            <EditFinancialInfoComponent
              v-if="editSection === EDIT_SECTIONS.financial"
              :financial="head(financialData)"
              @update="apiPayload = $event"
              @update:payload="value => hasSameValues = value"
            />
            <EditGeneralInfoComponent
              v-if="editSection === EDIT_SECTIONS.information"
              :info="information"
              @update="apiPayload = $event"
              @update:payload="value => hasSameValues = value"
            />
            <EditHardwareComponent
              v-if="editSection === EDIT_SECTIONS.hardware"
              :hardware="hardware"
              :vehicle-id="information.id"
              :allow-secondary-fuel-card="allowSecondaryFuelCard"
              :is-active="isActive"
              :is-non-connected="isNonConnected"
              :is-connected-car-platform="isConnectedCarPlatform"
              :cloud-boxx-device-id="cloudBoxxDeviceId ? cloudBoxxDeviceId : ''"
              @update="apiPayload = $event"
              @update:payload="value => hasSameValues = value"
            />
          </MuiValidationWrapper>
        </template>
        <div
          slot="footer"
          class="d-flex justify-content-center justify-content-sm-end align-items-center"
        >
          <CancelButton
            data-test-id="cancel-button"
            @click="closeModal"
          />

          <ui-button
            :disabled="!isValidatedForm || hasSameValues"
            :loading="isModalLoading"
            :type="BUTTON_TYPES.submit"
            class="wmin-initial"
            data-test-id="edit-button"
            form="EditVehicleForm"
          >
            Save
          </ui-button>
        </div>
      </GenericModalComponent>
    </ui-form>

    <VehicleAllocationFormComponent
      v-if="isVehicleAllocationFormVisible"
      :vehicle-allocation="vehicleAllocation"
      :initial="initialVehicleAllocationData"
      :callback="refreshAllocationIndex"
      :close-modal="closeVehicleAllocationModal"
      hide-vehicle
    />
    <RemoveVehicleAllocationFormComponent
      v-if="isRemoveModalVisible"
      :header="null"
      :vehicle-allocation="vehicleAllocation"
      :callback="refreshAllocationIndex"
      :close-modal="closeVehicleAllocationModal"
    />
  </div>
</template>
