var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "EditHardwareComponent" }, [
    _c(
      "div",
      { staticClass: "mb-4" },
      [
        _c(
          "label",
          { staticClass: "d-block emobg-font-weight-semibold mb-1" },
          [_vm._v(" Device Type ")]
        ),
        _c("ui-radio", {
          attrs: {
            value: _vm.payload.is_connected_car_platform,
            option: true,
            caption: "Connected Car Platform device",
            name: "is_connected_car_platform",
            "data-test-id": "connected_car-radio",
          },
          on: {
            changevalue: () => (_vm.payload.is_connected_car_platform = true),
          },
        }),
        _c("br"),
        _c("ui-radio", {
          attrs: {
            value: _vm.payload.is_connected_car_platform,
            option: false,
            caption: "iBoxx",
            name: "iBoxx",
            "data-test-id": "iboxx-radio",
          },
          on: {
            changevalue: () => (_vm.payload.is_connected_car_platform = false),
          },
        }),
      ],
      1
    ),
    _vm.payload.is_connected_car_platform
      ? _c(
          "div",
          { staticClass: "mb-4" },
          [
            _c("ContentCellComponent", {
              attrs: { value: _vm.cloudBoxxDeviceId, label: "Device number" },
            }),
          ],
          1
        )
      : _vm._e(),
    !_vm.payload.is_connected_car_platform
      ? _c(
          "div",
          { staticClass: "mb-4" },
          [
            _c(
              "MuiAlgoliaSelect",
              {
                staticClass: "w-100",
                attrs: {
                  filters: `${_vm.getOperatorFilter()} AND (vehicle_id: 'null' OR vehicle_id: ${
                    _vm.vehicleId
                  })`,
                  index: _vm.ALGOLIA_INDEXES.devices,
                  title: (result) => result.label_number,
                  "show-clear": !_vm.isActive,
                  label: `Device number${_vm.isIBoxxActive ? "*" : ""}`,
                  "on-clear": () => null,
                  name: "device_uuid",
                  "path-value": "uuid",
                  placeholder: "Select",
                  "data-test-id": "device-select",
                },
                model: {
                  value: _vm.payload.device_uuid,
                  callback: function ($$v) {
                    _vm.$set(_vm.payload, "device_uuid", $$v)
                  },
                  expression: "payload.device_uuid",
                },
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "emobg-font-weight-semibold",
                    attrs: { slot: "clear" },
                    slot: "clear",
                  },
                  [_vm._v(" Unlink device ")]
                ),
              ]
            ),
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      {
        class: {
          "mb-4": _vm.allowSecondaryFuelCard,
        },
      },
      [
        _c("MuiAlgoliaSelect", {
          staticClass: "w-100",
          attrs: {
            filters: `vehicle_id: 'null' OR vehicle_id: ${_vm.vehicleId} AND type: 'primary'`,
            index: _vm.ALGOLIA_INDEXES.fuelcards,
            title: (result) => `${result.company} - ${result.number}`,
            "path-value": "uuid",
            label: "Fuel card",
            name: "fuel_card_uuid",
            placeholder: "Select",
            "data-test-id": "fuelcard-select",
          },
          model: {
            value: _vm.payload.fuel_card_uuid,
            callback: function ($$v) {
              _vm.$set(_vm.payload, "fuel_card_uuid", $$v)
            },
            expression: "payload.fuel_card_uuid",
          },
        }),
      ],
      1
    ),
    _vm.allowSecondaryFuelCard
      ? _c(
          "div",
          [
            _c("MuiAlgoliaSelect", {
              staticClass: "w-100",
              attrs: {
                filters: `vehicle_id: 'null' OR vehicle_id: ${_vm.vehicleId} AND type: 'secondary'`,
                index: _vm.ALGOLIA_INDEXES.fuelcards,
                title: (result) => `${result.company} - ${result.number}`,
                label: "Secondary fuel card",
                name: "secondary_fuel_card_uuid",
                "path-value": "uuid",
                placeholder: "Select",
                "data-test-id": "secondary_fuelcard-select",
              },
              model: {
                value: _vm.payload.secondary_fuel_card_uuid,
                callback: function ($$v) {
                  _vm.$set(_vm.payload, "secondary_fuel_card_uuid", $$v)
                },
                expression: "payload.secondary_fuel_card_uuid",
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }