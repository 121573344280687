import get from 'lodash/get';
import {
  DATE_FORMAT,
  FALLBACK_MESSAGE,
  isFalsyString,
  isNullString,
  reformatDateTime,
  sentenceCase,
} from '@emobg/web-utils';
import { RECORD_TYPES } from '@emobg/motion-ui';
import carsharingRoutes from '@domains/Carsharing/router/CarsharingRouterMap';
import casesServicesRoutes from '@domains/CasesServices/router/CasesServicesRouterMap';
import { casesStatusColors } from '@domains/CasesServices/const/status';
import { CARSHARING_PERMISSIONS } from '@domains/Carsharing/const/permissions';
import { CRM_PERMISSIONS } from '@domains/CRM/const/permissions';
import crm from '@domains/CRM/router/CRMRouterMap';
import { PermissionLink } from '@/components';
import BadgeComponent from '@/components/BadgeComponent/BadgeComponent';

export function contentCells() {
  return [
    {
      attributeName: 'id',
      title: 'Id',
      displayPriority: 1,
      minWidth: 120,
      type: RECORD_TYPES.component,
      component: PermissionLink,
      props: result => ({
        classLink: 'emobg-link-primary emobg-body-2',
        to: {
          name: casesServicesRoutes.caseEvents.detail,
          params: {
            caseEventUuid: result.uuid,
          },
        },
        linkPermissions: [CRM_PERMISSIONS.viewCrmCases],
        text: `#${result.id}`,
      }),
    },
    {
      attributeName: 'case_type_internal_name',
      title: 'Case type',
      displayPriority: 1,
      minWidth: 260,
      transformValue: sentenceCase,
    },
    {
      attributeName: 'created_at',
      title: 'Creation date',
      displayPriority: 1,
      minWidth: 140,
      transformValue: value => reformatDateTime(
        value,
        DATE_FORMAT.defaultExtended,
        this.operatorTimezone,
      ),
    },
    {
      attributeName: 'reporting_user_id',
      title: 'Created by',
      displayPriority: 1,
      minWidth: 120,
      transformValue: result => (result && result.reporting_user_id === 1 ? 'System' : 'User'),
    },
    {
      attributeName: 'priority',
      title: 'Priority',
      displayPriority: 1,
      minWidth: 90,
      transformValue: sentenceCase,
    },
    {
      attributeName: 'status',
      title: 'Status',
      displayPriority: 1,
      minWidth: 120,
      type: RECORD_TYPES.component,
      component: BadgeComponent,
      props: ({ status }) => ({
        text: sentenceCase(status),
        color: casesStatusColors[status],
      }),
    },
    {
      title: 'Assignee',
      displayPriority: 2,
      minWidth: 180,
      type: RECORD_TYPES.component,
      component: PermissionLink,
      props: result => ({
        classLink: 'emobg-link-primary emobg-body-2',
        to: !isNullString(result.assignee_user_uuid) ? {
          name: crm.users.detail.index,
          params: {
            userUuid: result.assignee_user_uuid,
          },
        } : null,
        linkPermissions: [CRM_PERMISSIONS.viewCrmUsers],
        text: !isNullString(result.assignee_user_name) ? result.assignee_user_name : FALLBACK_MESSAGE.dash,
      }),
    },
    {
      title: 'User',
      displayPriority: 2,
      minWidth: 180,
      type: RECORD_TYPES.component,
      component: PermissionLink,
      props: result => ({
        classLink: 'emobg-link-primary emobg-body-2',
        to: !isNullString(result.user_uuid) ? {
          name: crm.users.detail.cases,
          params: {
            userUuid: result.user_uuid,
          },
        } : null,
        linkPermissions: [CRM_PERMISSIONS.viewCrmUsers],
        text: !isNullString(result.user_name) ? result.user_name : FALLBACK_MESSAGE.dash,
      }),
    },
    {
      attributeName: 'booking_uuid',
      title: 'Booking Id',
      displayPriority: 2,
      type: RECORD_TYPES.component,
      component: PermissionLink,
      props: result => ({
        classLink: 'emobg-link-primary emobg-body-2',
        to: !isFalsyString(result.booking_uuid) ? {
          name: carsharingRoutes.bookings.detail.index,
          params: {
            bookingUuid: result.booking_uuid,
          },
        } : null,
        linkPermissions: [CARSHARING_PERMISSIONS.viewCarsharingBookings],
        text: result.booking_fk ? `#${result.booking_fk}` : FALLBACK_MESSAGE.dash,
      }),
      minWidth: 70,
    },
    {
      attributeName: 'vehicle_fk',
      title: 'Vehicle Id',
      displayPriority: 2,
      type: RECORD_TYPES.template,
      minWidth: 90,
      template: result => {
        const vehiclePlate = get(result, 'vehicle_license_plate');
        const vehicleUrl = `/fleet/vehicles/${result && result.vehicle_uuid}`;
        const link = `<a href="${vehicleUrl}" class="emobg-link-primary emobg-body-2">${vehiclePlate}</a>`;
        const hasVehiclegData = !isFalsyString(result.vehicle_fk);

        return hasVehiclegData ? link : this.FALLBACK_MESSAGE.dash;
      },
    },
    {
      attributeName: 'main_entity',
      title: 'Main entity',
      displayPriority: 0,
      minWidth: 70,
      transformValue: sentenceCase,
    },
    {
      attributeName: 'city_name',
      title: 'City',
      displayPriority: 0,
      minWidth: 70,
      transformValue: sentenceCase,
    },
    {
      title: 'Reporting user',
      displayPriority: 0,
      type: RECORD_TYPES.component,
      component: PermissionLink,
      props: result => ({
        classLink: 'emobg-link-primary emobg-body-2',
        to: !isNullString(result.reporting_user_uuid) ? {
          name: crm.users.detail.index,
          params: {
            userUuid: result.reporting_user_uuid,
          },
        } : null,
        linkPermissions: [CRM_PERMISSIONS.viewCrmUsers],
        text: !isNullString(result.reporting_user_name) ? result.reporting_user_name : FALLBACK_MESSAGE.dash,
      }),
    }];
}

export const caseEventsFacets = [
  {
    type: 'refinementList',
    props: {
      title: 'City',
      attributeName: 'city_name',
    },
  },
  {
    type: 'rangedatetimepicker',
    props: {
      title: 'End date',
      attributeName: 'created_at',
      labels: {
        from: 'From',
        to: 'To',
        removeButton: 'Clear dates',
      },
      from: null,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Case type',
      attributeName: 'case_type_internal_name',
      toggled: false,
      transformValue: sentenceCase,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Main entity',
      attributeName: 'main_entity',
      transformValue: sentenceCase,
      toggled: false,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Status',
      attributeName: 'status',
      transformValue: sentenceCase,
      toggled: false,
    },
  },
  {
    type: 'refinementOptions',
    props: {
      title: 'Created by',
      attributeName: 'reporting_user_id',
      showTooltip: true,
      options: [
        {
          label: 'System',
          value: '1',
        },
        {
          label: 'User',
          value: '-1',
        },
      ],
    },
  },
];
