import { RECORD_TYPES } from '@emobg/motion-ui';
import {
  DATE_FORMAT,
  FALLBACK_MESSAGE,
  getValue,
  isFalsyString,
  reformatDateTime,
  sentenceCase,
  titleCase,
} from '@emobg/web-utils';

import crm from '@domains/CRM/router/CRMRouterMap';
import { CARSHARING_PERMISSIONS } from '@domains/Carsharing/const/permissions';
import fleet from '@domains/Carsharing/router/FleetRouterMap';
import { PermissionLink } from '@/components';
import { CRM_PERMISSIONS } from '@/domains/CRM/const/permissions';
import BadgeComponent from '@/components/BadgeComponent/BadgeComponent';
import DamageImageTemplate from '@/domains/Carsharing/Vehicles/VehicleDamages/components/DamageImageTemplate';
import { DAMAGES_STATUS_COLORS, DAMAGE_BLUEPRINT_SIDE_NAMES } from '../../../VehicleDamages/damages.const';
import InvestigationStatusBadge
  from '../../../VehicleDamages/Investigation/components/InvestigationStatusBadge.vue';

export const contentCells = (openPreviewModal, timezone) => [
  {
    title: 'ID',
    displayPriority: 1,
    minWidth: 100,
    type: RECORD_TYPES.component,
    component: PermissionLink,
    props: damage => ({
      classLink: 'd-block ellipsis emobg-link-primary emobg-body-2',
      to: {
        name: fleet.damages.edit,
        params: {
          vehicleUuid: damage.vehicle.uuid,
          damageUuid: damage.uuid,
        },
      },
      linkPermissions: [CARSHARING_PERMISSIONS.viewCarsharingDamages],
      text: `#${damage.id}`,
    }),
  },
  {
    title: 'Damage picture',
    displayPriority: 1,
    minWidth: 120,
    type: RECORD_TYPES.component,
    component: DamageImageTemplate,
    props: ({ image_url: imageUrl }) => ({
      class: imageUrl ? 'cursor-pointer' : '',
      src: imageUrl,
      hasBorderRadius: true,
      height: 80,
      borderSizeClass: 'emobg-border-1',
      onClickImage: () => openPreviewModal(imageUrl),
    }),
  },
  {
    title: 'Vehicle side',
    attributeName: 'side',
    minWidth: 100,
    displayPriority: 1,
    transformResult: ({ blueprint, side }) => {
      const sideKey = side || blueprint.substring(blueprint.lastIndexOf('_') + 1, blueprint.lastIndexOf('.'));
      return DAMAGE_BLUEPRINT_SIDE_NAMES[sideKey].label;
    },
  },
  {
    attributeName: 'vehicle_area',
    title: 'Vehicle area',
    displayPriority: 1,
    minWidth: 120,
    transformValue: sentenceCase,
  },
  {
    attributeName: 'reporting_date',
    title: 'Reporting date',
    minWidth: 120,
    displayPriority: 1,
    transformValue: value => value ? reformatDateTime(value, DATE_FORMAT.defaultExtended, timezone) : FALLBACK_MESSAGE.dash,
  },
  {
    attributeName: 'originator_user',
    title: 'Agent',
    displayPriority: 1,
    minWidth: 120,
    type: RECORD_TYPES.component,
    component: PermissionLink,
    props: damage => {
      const userUuid = getValue(damage, 'originator_user.uuid', 'null');
      const userDetails = `${getValue(
        damage,
        'originator_user.first_name',
      )} ${getValue(damage, 'originator_user.last_name')}`;
      const hasUserData = !isFalsyString(userUuid);

      return {
        classLink: 'd-block ellipsis emobg-link-primary emobg-body-2',
        to: {
          name: crm.users.detail.index,
          params: {
            userUuid,
          },
        },
        linkPermissions: hasUserData ? [CRM_PERMISSIONS.viewCrmUsers] : [],
        text: hasUserData ? titleCase(userDetails) : FALLBACK_MESSAGE.dash,
      };
    },
  },
  {
    attributeName: 'status',
    displayPriority: 1,
    minWidth: 150,
    title: 'Damage',
    type: RECORD_TYPES.component,
    component: BadgeComponent,
    props: ({ status }) => ({
      text: sentenceCase(status),
      color: DAMAGES_STATUS_COLORS[status],
      class: 'd-flex',
    }),
  },
  {
    attributeName: 'investigation.status',
    displayPriority: 1,
    minWidth: 100,
    title: 'Investigation',
    type: RECORD_TYPES.component,
    component: InvestigationStatusBadge,
    props: damage => {
      const status = getValue(damage, 'investigation.status', undefined);
      const isNullString = isFalsyString(status);
      return {
        status: isNullString ? null : status,
      };
    },
  },
  {
    attributeName: 'investigation.resolution_status',
    displayPriority: 1,
    minWidth: 135,
    title: 'Resolution',
    type: RECORD_TYPES.component,
    component: InvestigationStatusBadge,
    props: damage => {
      const resolutionStatus = getValue(damage, 'investigation.resolution_status', undefined);
      const isNullString = isFalsyString(resolutionStatus);
      return {
        status: isNullString ? null : resolutionStatus,
        isResolution: true,
      };
    },
  },
  {
    attributeName: 'damage_type',
    title: 'Damage type',
    displayPriority: 0,
    transformValue: sentenceCase,
  },
  {
    attributeName: 'description',
    title: 'Description',
    minWidth: 120,
    displayPriority: 0,
  },
  {
    attributeName: 'reporting_user',
    title: 'Reporting user',
    displayPriority: 0,
    type: RECORD_TYPES.component,
    component: PermissionLink,
    props: damage => {
      const userUuid = getValue(damage, 'reporting_user.uuid', 'null');
      const userDetails = `${getValue(
        damage,
        'reporting_user.first_name',
      )} ${getValue(damage, 'reporting_user.last_name')}`;
      const hasUserData = !isFalsyString(userUuid);

      return {
        classLink: 'd-block ellipsis emobg-link-primary emobg-body-2',
        to: {
          name: crm.users.detail.index,
          params: {
            userUuid,
          },
        },
        linkPermissions: hasUserData ? [CRM_PERMISSIONS.viewCrmUsers] : [],
        text: hasUserData ? titleCase(userDetails) : FALLBACK_MESSAGE.dash,
      };
    },
  },
  {
    attributeName: 'responsible_user',
    title: 'Responsible user',
    displayPriority: 0,
    type: RECORD_TYPES.component,
    component: PermissionLink,
    props: damage => {
      const userUuid = getValue(damage, 'responsible_user.uuid', 'null');
      const userDetails = `${getValue(
        damage,
        'responsible_user.first_name',
      )} ${getValue(damage, 'responsible_user.last_name')}`;
      const hasUserData = !isFalsyString(userUuid);

      return {
        classLink: 'd-block ellipsis emobg-link-primary emobg-body-2',
        to: {
          name: crm.users.detail.index,
          params: {
            userUuid,
          },
        },
        linkPermissions: hasUserData ? [CRM_PERMISSIONS.viewCrmUsers] : [],
        text: hasUserData ? titleCase(userDetails) : FALLBACK_MESSAGE.dash,
      };
    },
  },
];
